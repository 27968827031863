import { map as m, filter as f,min,apply,range,juxt,gt,inc,compose as C, __, fromPairs, groupBy as g, 
    head, identity, ifElse, includes, join, last,prop as p, partition, props, uniq, values} from 'ramda';
import { initialState } from './initial';
import { calc_nodes_and_links } from './simulation';
import { set_filters, set_nodes, set_links,set_yearrange, set_selected,set_selected_filter, set_url, 
    set_all_data, reset_departments, toggle_current, set_width,set_height,toggle_filters,toggle_map,
    set_svg_side,set_timeline} from './ui_state';
const recalc = (state) => (dispatch) => {
    const { api: { queries: {"projects(undefined)":{data}} }, ui } = state;
    const [nodes, links] = calc_nodes_and_links(data, ui);
    dispatch(set_nodes(nodes));
    dispatch(set_links(links));
};
const getSelection = (allData, params, project, tab) => {
    const ui_id_match = xs => xs.find(x => x.ui_id === params.selected);
    const tab_match = xs => xs.find(x => x.slug === project + "/" + tab);
    const slug_match = xs => xs.find(x => x.slug === project);

    console.log(params,allData, ui_id_match(allData), tab_match(allData), slug_match(allData)); 

    return ui_id_match(allData) || tab_match(allData) || slug_match(allData);
};
const parseUrlParams = (urlParams) => {
    const parseNum = ifElse(C(isNaN, Number), identity, Number);
    const params = C(
        ([arrays, simple]) => ({
        ...C(m(m(C(parseNum, last))), g(head), m(([key, val]) => [key.replace("[]", ""), val]))(arrays),
        ...C(m(C(parseNum, last, last)), g(head))(simple)
        }),
        partition(C(includes("[]"), head))
    );
    return params([...urlParams]);
};
const calc_yearrange = (params, timelineRange, selection) => {
    console.log(params,timelineRange, selection);

    return (params.high || params.low) ? { high: Number(params.high), low: Number(params.low) } :
        params.filter ? { high: last(timelineRange), low: head(timelineRange) } :
        (selection && selection.end < initialState.yearrange.low) ? 
            { low: selection.end || selection.start, high: selection.end || selection.start } :
        initialState.yearrange;
};
const init_departments = (departments, params) => {
    return C(
        fromPairs,
        m(x => [x.id, { ...x, show: params.topics ? !includes(x.id, params.topics) : true }]),
        f(p("icon"))
    )(departments);
};
const setFilters = () => (dispatch, getState) => {
    const { ui: { selected_filter, departments, language, 
        only_current, width, timeline_range, all_data,yearrange: { low, high } } } = getState();
    const department_icons = C(values, m(props(["name", "icon", "id"])), f(p("show")))(departments);
    const { yearrange: { low: i_low, high: i_high } } = initialState;
    const selected_filtering = (group, nimi) => {
        const value = all_data.find(x => x.ui_id === selected_filter)?.[(language === "EN" && head(selected_filter) === "c") ? "name_eng" : "name"];
        const add_all = ((head(timeline_range) === low && last(timeline_range) === high) ?  (language === "EN" ? " all" : " kõik") : "")
        const same_group = selected_filter && (head(selected_filter) === "i" ? "inimene" : "field") === group;
        return (same_group) ? [{ nimi, values: [value+add_all]}] : [];}
    const filtrid = [
        ...(i_low !== low || i_high !== high) ? 
        [{ nimi: language === "EN" ? "period" : "periood", values: [join("-", uniq([low, high]))] }] : [],
        ...(selected_filtering("inimene", language === "EN" ? "person" : "inimene")),
        ...(selected_filtering("field", language === "EN" ? "topic" : "teema")),
        ...(department_icons.length !== values(departments).length ? [{ nimi:  language === "EN" ? "areas" : "valdkonnad", values: department_icons }] : []),
        ...(!only_current && (width > 720) ? [{ nimi:  language === "EN" ? "co-authors" : "kaasautorid", values: [language === "EN" ? "show all" : "näita kõiki"] }] : [])
    ];
    dispatch(set_filters(filtrid));
};

export const load_timeline = (projects) => (dispatch) => {
  const timeline = C(
    apply(range),
    juxt([
      C(apply(Math.min), f(gt(__, 1)), m(p("start"))),
      C(inc, apply(Math.max), m(p("end")))])
  )(projects);
  dispatch(set_timeline(timeline));
};
export const resize = () => (dispatch, getState) => {
    const h = document.clientHeight || window.innerHeight;
    const w = document.clientWidth || window.innerWidth;
    dispatch(set_width(w));
    dispatch(set_height(h));
    dispatch(toggle_filters(w > 1200));
    dispatch(toggle_map(w > 1200));
    dispatch(set_svg_side(min(w, h)));
};
export const load_url = ({ projects, categories, people, departments, prev_state: prev }) => (dispatch, getState) => {
  const urlParams = new URLSearchParams(window.location.search);
  const params = prev || parseUrlParams(urlParams);
  const allData = [...people, ...categories, ...projects];
  const eng = window.location.pathname.split("/")?.[1] === "en";
  const home_reset = window.location.pathname === "/";
  const [, , project, tab] = window.location.pathname.split("/").slice(eng ? 1 : 0);
  const { ui: { timeline_range, all_data,selected } } = getState();
  const selection = getSelection(allData, params, project, tab);
 
  if (!all_data)              dispatch(set_all_data(allData));
                              dispatch(reset_departments(init_departments(departments, params)));
                              dispatch(set_yearrange(home_reset ? initialState.yearrange : calc_yearrange(params, timeline_range, selection)));
  if (home_reset || prev || urlParams.size) dispatch(set_url({ params,...(home_reset ? initialState.yearrange : {}), path: window.location.pathname }));
  if (prev || params.authors) dispatch(toggle_current(!params.authors));
  if (home_reset || prev || params.filter)  dispatch(set_selected_filter(home_reset ? undefined : params.filter));
  if (home_reset || !prev && selected?.ui_id !== selection?.ui_id && selection) {
    dispatch(set_selected(home_reset ? undefined : selection));
  }
  if (!prev || urlParams.size) dispatch(setFilters());
                               dispatch(recalc(getState()));
};