import { compose as C, map as m, prop as p, defaultTo, path} from "ramda";
import { useSelector } from "react-redux";
import { useLangQuery } from "../../app/api";

export const Partners = ({data}) => {
    const language = useSelector(path(["ui","language"]));
    const {data:translation} = useLangQuery(language);
    return data?.Projekt_Partner?.length ? <div className="partners">
        <h3>{translation?.["funding"]}</h3>
        {C( 
            m(({comment,Partnerid:x}) => <>
                <p key={`partner_comment_${x.id}`} className="partner_comment">{comment}</p>
                <p key={`partner_${x.id}`} className="partner" >{x.name}</p>
            </>),
            defaultTo([]),
            p("Projekt_Partner"),
        )(data)}
    </div> : null; 
}